import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import { RetellWebClient } from "retell-client-js-sdk";
import CallIcon from "@mui/icons-material/Call";
const token = process.env.REACT_APP_RETELL_API_TOKEN;
const headers = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

const sdk = new RetellWebClient();

const TestAudio = ({ agentID, loading }) => {
  const [audioContext, setAudioContext] = useState(null);
  const [audioSource, setAudioSource] = useState(null);
  const audioBufferQueue = useRef([]);
  const [startTalking, setStartTalking] = useState(false);
  useEffect(() => {
    // Initialize the AudioContext when the component mounts
    const context = new (window.AudioContext || window.webkitAudioContext)();
    setAudioContext(context);

    // Cleanup the AudioContext when the component unmounts
    return () => {
      context.close();
    };
  }, []);

  const registerCall = () => {
    axios
      .post(
        `https://api.retellai.com/register-call`,
        {
          audio_encoding: "s16le",
          audio_websocket_protocol: "web",
          agent_id: agentID,
          sample_rate: 24000,
        },
        headers
      )
      .then((registerCallResponse) => {
        console.log("test audio data", registerCallResponse.data);
        sdk.startConversation({
          callId: registerCallResponse.data.call_id,
          sampleRate: registerCallResponse.data.sample_rate,
          enableUpdate: true, // (Optional) You want to receive the update event such as transcript
          // customStream: yourStream, // (Optional) You can use your own MediaStream which might use a different mic
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const endCall = () => {
    setStartTalking(false);
    sdk.stopConversation();
    setAudioContext(null);
    setAudioSource(null);
  };

  // Setup event listeners
  useEffect(() => {
    sdk.on("conversationStarted", () => {
      setStartTalking(true);
      console.log("Conversation started");
    });

    sdk.on("conversationEnded", () => {
      console.log("Conversation ended");
      endCall();
    });

    sdk.on("error", (error) => {
      console.error("An error occurred:", error);
    });

    // sdk.on("update", (update) => {
    //     console.log("update", update);
    // });

    sdk.on("metadata", (metadata) => {
      console.log("metadata", metadata);
    });

    sdk.on("audio", (audio) => {
      console.log("There is audio");
      if (audio) {
        audioBufferQueue.current.push(audio);
        playAudio();
      }
    });

    // sdk.on("agentStartTalking", () => {
    //     console.log("agentStartTalking");
    // });

    sdk.on("agentStopTalking", () => {
      console.log("agentStopTalking");
    });
  }, []);

  const playAudio = () => {
    if (audioBufferQueue.current.length > 0 && audioContext) {
      const audioData = audioBufferQueue.current.shift();

      audioContext.decodeAudioData(
        audioData,
        (buffer) => {
          const source = audioContext.createBufferSource();
          source.buffer = buffer;
          source.connect(audioContext.destination);
          source.start(0);

          setAudioSource(source);
        },
        (error) => {
          console.error("Error decoding audio data:", error);
        }
      );
    }
  };

  return (
    <React.Fragment>
      {startTalking ? (
        <Button variant="contained" color="error" onClick={endCall}>
          End Call
        </Button>
      ) : (
        <Button
          variant="contained"
          className="test-agent-button"
          onClick={registerCall}
          style={{
            backgroundColor: "#aac955",
          }}
          disabled={loading}
        >
          {/* <CallIcon style={{fontSize:19}} />
                    &nbsp; */}
          Test Agent
        </Button>
      )}
    </React.Fragment>
  );
};

export default TestAudio;
