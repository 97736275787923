import { Button } from "@mui/material";
import * as XLSX from "xlsx";
import React from "react";
import { useAuth } from "../../../hooks/useAuth";
const exportToExcel = (data, fileName = "call_data.xlsx") => {
  const formatDate = (date) => {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const seconds = d.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return `${month}/${day}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
  };
  // Transform data to match Excel format

  const transformedData = data.map((item) => ({
    call_id: item.call_id,
    agent_id: item.agent_id,
    audio_websocket_protocol: item.audio_websocket_protocol,
    audio_encoding: item.audio_encoding,
    sample_rate: item.sample_rate,
    call_status: item.call_status,
    start_timestamp: formatDate(item.start_timestamp),
    end_timestamp: formatDate(item.end_timestamp),
    transcript: item.transcript,
    recording_url: item.recording_url,
    public_log_url: item.public_log_url,
    disconnection_reason: item.disconnection_reason,
    from_number: item.from_number,
    to_number: item.to_number,
    direction: item.direction,
    metadata_twilio_call_sid: item.metadata?.twilio_call_sid,
    cost_metadata_llm_model: item.cost_metadata?.llm_model,
    cost_metadata_telecommunication: item.cost_metadata?.telecommunication,
    cost_metadata_voice_provider: item.cost_metadata?.voice_provider,
    agent_task_completion_rating:
      item.call_analysis?.agent_task_completion_rating,
    agent_task_completion_rating_reason:
      item.call_analysis?.agent_task_completion_rating_reason,
    call_summary: item.call_analysis?.call_summary,
    user_sentiment: item.call_analysis?.user_sentiment,
    call_completion_rating: item.call_analysis?.call_completion_rating,
    call_completion_rating_reason:
      item.call_analysis?.call_completion_rating_reason,
    agent_sentiment: item.call_analysis?.agent_sentiment,
    opt_out_sensitive_data_storage: item.opt_out_sensitive_data_storage,
    voice_id: item.agent_details?.voice_id,
    voice_speed: item.agent_details.voice_speed
      ? parseInt(item.agent_details.voice_speed).toFixed(2)
      : "",
    voice_temperature: item.agent_details.voice_temperature
      ? parseInt(item.agent_details.voice_temperature).toFixed(2)
      : "",
    interruption_sensitivity: item.agent_details.interruption_sensitivity
      ? parseInt(item.agent_details.interruption_sensitivity).toFixed(2)
      : "",
  }));

  // Create a worksheet
  const worksheet = XLSX.utils.json_to_sheet(transformedData);

  // Create a workbook and add the worksheet to it
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Calls");

  // Write the workbook and trigger a download
  XLSX.writeFile(workbook, fileName);
};

function Export({ loading, callData, agentDetails }) {
  const { apiConfig } = useAuth();
  return (
    <Button
      variant="outlined"
      style={{ borderColor: "#333", color: "#333", margin: 5 }}
      onClick={() => {
        exportToExcel(
          apiConfig === "retell"
            ? callData.map((item) => ({ ...item, agent_details: agentDetails }))
            : callData
        );
      }}
      disabled={loading}
    >
      {/* <CallIcon style={{fontSize:19}} />
                    &nbsp; */}
      Export to Excel
    </Button>
  );
}

export default Export;
