import { createContext, useContext, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { getRoleByID } from "../components/pages/utils/getRole";
const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [apiConfig, setApiConfig] = useLocalStorage("apiConfig", null);
  const navigate = useNavigate();
  useEffect(() => {
    console.log(localStorage.getItem("apiConfig") === "null");
    if (localStorage.getItem("apiConfig") === "null") {
      setApiConfig("retell");
    }
  }, []);

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);
    setApiConfig("retell");
    navigate("/report");
  };
  const changeConfig = (data) => {
    setApiConfig(data);
  };
  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      apiConfig,
      changeConfig,
    }),
    [user, apiConfig]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
