import React from "react";

function NoDataAvailable() {
  return (
    <div
      style={{
        // border:"solid 1px #eeecec",
        marginTop: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 25,
      }}
    >
      <h2 style={{ color: "#444" }}>No Data Available</h2>
      <img src="./images/undraw_empty_re_opql.svg" width={500} />
    </div>
  );
}

export default NoDataAvailable;
