import { CircularProgress } from "@mui/material";
import React from "react";

function PreparingData({ label }) {
  return (
    <div
      style={{
        // border:"solid 1px #eeecec",
        marginTop: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 25,
        flexDirection: "column",
      }}
    >
      <h2 style={{ color: "#444" }}>{label}</h2>
      <br />
      <br />
      <CircularProgress />
    </div>
  );
}

export default PreparingData;
