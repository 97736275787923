import * as React from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import CallDetails from "./CallDetails";
import formatDate from "../../../utils/date_formatter";

function Row(item) {
  const { data } = item;
  const [open, setOpen] = React.useState(false);

  const startDate = new Date(data.start_timestamp);
  const endDate = new Date(data.end_timestamp);

  // Get the difference in milliseconds
  const diffMilliseconds = endDate - startDate;
  const diffSeconds = Math.floor((diffMilliseconds / 1000).toFixed(1));
  let cost = diffSeconds * 0.0022;
  return (
    <React.Fragment>
      <TableRow sx={!open ? { "& > *": { borderBottom: "unset" } } : {}}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <a
            style={{ cursor: "pointer", fontWeight: 500 }}
            onClick={() => setOpen(!open)}
          >
            <u>{data.call_id}</u>
          </a>
        </TableCell>
        <TableCell align="center">{formatDate(data.start_timestamp)}</TableCell>
        <TableCell align="center">{formatDate(data.end_timestamp)}</TableCell>
        <TableCell align="center">{diffSeconds ? diffSeconds : "--"}</TableCell>
        <TableCell align="center">
          {data.call_analysis ? data.call_analysis.user_sentiment : "--"}
        </TableCell>
        <TableCell align="center">
          {data.call_analysis ? data.call_analysis.agent_sentiment : "--"}
        </TableCell>
        <TableCell align="center">
          {data.disconnection_reason ? data.disconnection_reason : "--"}
        </TableCell>
        <TableCell align="center">
          {cost ? `$${cost.toFixed(3)}` : "--"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {data.call_analysis ? (
              <CallDetails data={data} cost={cost} />
            ) : (
              <div
                style={{
                  // border:"solid 1px #eeecec",
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 25,
                  flexDirection: "column",
                }}
              >
                <h2 style={{ color: "#444" }}>
                  No data available on this call
                </h2>
                <br />
              </div>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ callData }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper style={{ width: "100%" }}>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Call ID</TableCell>
              <TableCell align="center">Start Time</TableCell>
              <TableCell align="center">End Time</TableCell>
              <TableCell align="center">AHT</TableCell>
              <TableCell align="center">User Sentiment</TableCell>
              <TableCell align="center">Agent Sentiment</TableCell>
              <TableCell align="center">Disconnection Reason</TableCell>
              <TableCell align="center">Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {callData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <Row key={item.call_id} data={item} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15]}
        component="div"
        count={callData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
