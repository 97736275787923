import React, { useEffect, useState } from "react";

import { LineChart } from "@mui/x-charts/LineChart";

// Function to convert timestamps to date strings
const convertTimestampToDate = (timestamp) => {
  const options = { year: "numeric", month: "long", day: "numeric" };

  const date = new Date(timestamp);
  return date.toLocaleDateString("en-US", options); // Extracting only the date part
};

// Function to process call data and count transfers per day
const processCallData = (callData) => {
  const callTransferCounts = {};
  callData.forEach((call) => {
    if (call.disconnection_reason === "call_transfer") {
      const date = convertTimestampToDate(call.start_timestamp);
      if (callTransferCounts[date]) {
        callTransferCounts[date] += 1;
      } else {
        callTransferCounts[date] = 1;
      }
    }
  });

  return callTransferCounts;
};

// Process call data to get call transfer counts

export default function SimpleLineChart({ callData }) {
  const [xLabels, setXlabel] = useState([]);
  const [pData, setPdata] = useState([]);
  useEffect(() => {
    if (callData !== undefined) {
      const callTransferCounts = processCallData(callData);
      const labels = Object.keys(callTransferCounts);
      const data = Object.values(callTransferCounts);
      setXlabel(labels);
      setPdata(data);
    }
  }, [callData]);

  return (
    <LineChart
      colors={[
        "#509ee3",
        "#f28e2c",
        "#e15759",
        "#76b7b2",
        "#59a14f",
        "#edc949",
        "#af7aa1",
        "#ff9da7",
        "#9c755f",
        "#bab0ab",
      ]}
      height={500}
      yAxis={[{ data: pData }]}
      series={
        callData.length > 0 ? [{ data: pData, label: "Call Transfers" }] : []
      }
      xAxis={[{ scaleType: "point", data: xLabels }]}
    />
  );
}
