import { Box, Container, Grid, TextField } from "@mui/material";
import React from "react";

const CreateUser = () => {
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: 15,
              }}
            >
              <h2>Create Agent</h2>
            </div>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default CreateUser;
