import { CircularProgress } from "@mui/material";
import React from "react";

const Loader = () => {
  return (
    <div
      style={{
        // border:"solid 1px #eeecec",
        marginTop: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 25,
        flexDirection: "column",
        height: 500,
        width: "100%",
      }}
    >
      <h2 style={{ color: "#444" }}>Preparing Reports...</h2>
      <br />
      <CircularProgress />
    </div>
  );
};

export default Loader;
