import axios from "axios";
const endpointAPI = process.env.REACT_APP_MINDVOICE_API;

const addFeedback = (token, data) => {
  console.log("fb", data);
  return axios.post(`${endpointAPI}/feedback/`, data, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

const getCallFeedback = (token, callID) => {
  return axios.get(`${endpointAPI}/feedback/call/${callID}/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export { addFeedback, getCallFeedback };
