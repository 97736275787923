import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./components/pages/Login";

import "./App.css";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AuthProvider } from "./hooks/useAuth";
import Reports from "./components/pages/Reports";
import Layout from "./components/layout/Layout";
import CreateUser from "./components/pages/CreateUser";
import Permission from "./components/pages/Permission";
import Config from "./components/pages/Config";
import Extractor from "./components/pages/Extractor";
import Outbound from "./components/pages/Outbound";
function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/samplee" element={<LoginPage />} />
        <Route
          path="/report"
          element={
            <ProtectedRoute>
              <Layout component={<Reports />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user"
          element={
            <ProtectedRoute>
              <Layout component={<CreateUser />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/permission"
          element={
            <ProtectedRoute>
              <Layout component={<Permission />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/outbound"
          element={
            <ProtectedRoute>
              <Layout component={<Outbound />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/config"
          element={
            <ProtectedRoute>
              <Layout component={<Config />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/extractor"
          element={
            <ProtectedRoute>
              <Layout component={<Extractor />} />
            </ProtectedRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
}

export default App;
