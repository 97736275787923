import axios from "axios";
const endpointAPI = process.env.REACT_APP_MINDVOICE_API;

const getUserData = (token) => {
  return axios.get(`${endpointAPI}/user/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

const getUserProfile = async (token, username) => {
  return await axios.get(`${endpointAPI}/user/${username}/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export { getUserData, getUserProfile };
