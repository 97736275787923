import axios from "axios";
const endpointAPI = process.env.REACT_APP_RETELL_API;
const token = process.env.REACT_APP_RETELL_API_TOKEN;

const getAgentList = () => {
  return axios.get(`${endpointAPI}/list-agents`, {
    // params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export { getAgentList };
