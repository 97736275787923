import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function Modal(props) {
  let { handleClose, open, title, dialogAction } = props;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "solid 1px #eee",
            textAlign: "center",
            paddingBottom: 10,
          }}
        >
          <span style={{ color: "#555" }}>
            {title ? title : "Insert Title"}
          </span>
          <div>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>{props.children}</DialogContent>
        {/* <DialogActions style={{ paddingRight: 30, paddingBottom: 30 }}>
          {dialogAction}
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
