import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BarChartIcon from "@mui/icons-material/BarChart";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export const mainListItems = (location, userRoleDetail) => {
  const navList = [
    {
      to: "/user",
      label: "Create Agent",
      iconComponent: <PersonAddIcon />,
      permission: userRoleDetail.modify_agent,
    },
    {
      to: "/report",
      label: "Report",
      iconComponent: <BarChartIcon />,
      permission: userRoleDetail.modify_report,
    },
    {
      to: "/outbound",
      label: "Outbound",
      iconComponent: <PhoneForwardedIcon />,
      permission: userRoleDetail.outbound,
    },
    {
      to: "/extractor",
      label: "Extractor",
      iconComponent: <ImportExportIcon />,
      permission: true,
    },
    {
      to: "/permission",
      label: "Permission",
      iconComponent: <AdminPanelSettingsIcon />,
      permission: userRoleDetail.modify_roles,
    },
    {
      to: "/config",
      label: "Config",
      iconComponent: <SettingsIcon />,
      permission: true,
    },
  ];
  const styles = {
    background: "#ece8eb",
    color: "#aac955",
  };

  return (
    <React.Fragment>
      {navList.map((item) => {
        if (item.permission !== undefined && item.permission) {
          return (
            <Link to={item.to} className="nav-link">
              <ListItemButton
                style={location.pathname === item.to ? styles : {}}
              >
                <ListItemIcon
                  style={location.pathname === item.to ? styles : {}}
                >
                  {item.iconComponent}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  style={location.pathname === item.to ? styles : {}}
                />
              </ListItemButton>
            </Link>
          );
        }
      })}
    </React.Fragment>
  );
};

export const secondaryListItems = (
  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
  </React.Fragment>
);
