import * as React from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import EditUserForm from "./EditUserForm";
import Modal from "../common/Modal";

function Row(item) {
  let { data, getRoleDetail, roleList } = item;
  const [open, setOpen] = React.useState(false);
  const handleOpenEdit = () => {
    setOpen(true);
  };
  const handleCloseEdit = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <TableRow sx={!open ? { "& > *": { borderBottom: "unset" } } : {}}>
        <TableCell align="left">
          <a style={{ cursor: "pointer", fontWeight: 500 }}>
            <u>{data.uuid}</u>
          </a>
        </TableCell>
        <TableCell align="left">
          {data.first_name ? data.first_name : "--"}
        </TableCell>
        <TableCell align="left">
          {data.last_name ? data.last_name : "--"}
        </TableCell>
        <TableCell align="left">
          {data.username ? data.username : "--"}
        </TableCell>
        <TableCell align="left">{data.email ? data.email : "--"}</TableCell>
        <TableCell align="left">{data.role_name}</TableCell>
        <TableCell align="left">
          <span onClick={handleOpenEdit} className="action-title">
            Edit
          </span>
        </TableCell>
      </TableRow>
      <Modal open={open} handleClose={handleCloseEdit} title="Edit Form Form">
        <EditUserForm
          data={data}
          handleClose={handleCloseEdit}
          getRoleDetail={getRoleDetail}
          roleList={roleList}
        />
      </Modal>

      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit></Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}

export default function UsersTable({ userData, getRoleDetail, roleList }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper style={{ width: "100%" }}>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="left">User ID</TableCell>
              <TableCell align="left">First name</TableCell>
              <TableCell align="left">Last name</TableCell>
              <TableCell align="left">Username</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Role</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => {
                console.log("item", item.profile.role);
                item.role_name = getRoleDetail(item.profile.role)
                  ? getRoleDetail(item.profile.role).name
                  : item.profile.role;
                return (
                  <Row
                    key={item.username}
                    data={item}
                    getRoleDetail={getRoleDetail}
                    roleList={roleList}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15]}
        component="div"
        count={userData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
