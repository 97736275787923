import * as React from "react";
import Box from "@mui/material/Box";
import ReactAudioPlayer from "react-audio-player";
import Typography from "@mui/material/Typography";
import { Button, Divider, Grid, Tab, Tabs } from "@mui/material";
import formatDate from "../../../utils/date_formatter";
import ShareFeedback from "./ShareFeedback";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Feedbacks from "./Feedbacks";
import Transcripts from "./tabs/Transcripts";

const CallDetails = ({ data, cost }) => {
  const audioPlayerRef = React.useRef(null);
  const lines = data.transcript.split("\n");
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("1");
  const [feedbacks, setFeedbacks] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const jumpToTime = (time) => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.audioEl.current.currentTime = time;
      audioPlayerRef.current.audioEl.current.play();
    }
  };

  return (
    <Box sx={{ margin: 1 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography component="div" style={{ padding: 5, width: "60%" }}>
          <span style={{ fontSize: 18, fontWeight: 400 }}>Call Summary</span>
          <p style={{ fontSize: 15, fontWeight: 400 }}>
            {data.call_analysis.call_summary}
          </p>
        </Typography>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={handleClickOpen}
            style={{
              color: "#555",
            }}
          >
            Share Feedback
          </Button>
          &nbsp; &nbsp; &nbsp;
          <ReactAudioPlayer
            ref={audioPlayerRef}
            src={data.recording_url}
            controls
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          border: "solid 1px #F1F1F1",
          marginTop: 10,
        }}
      >
        <Box sx={{ width: "60%" }}>
          <TabContext
            value={value}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="lab API tabs example"
              >
                <Tab label="Transcript" value="1" className="call-tab-label" />
                <Tab label="QA Feedback" value="2" className="call-tab-label" />
                <Tab label="Extracts" value="3" className="call-tab-label" />
              </TabList>
            </Box>
            <TabPanel value="1" style={{ padding: 0 }}>
              {console.log(data)}
              <Transcripts
                jumpToTime={jumpToTime}
                transcript={data.transcript_with_tool_calls}
              />
            </TabPanel>
            <TabPanel value="2" style={{ maxHeight: 500, overflow: "auto" }}>
              <Feedbacks
                setFeedbacks={setFeedbacks}
                feedbacks={feedbacks}
                callID={data.call_id}
              />
            </TabPanel>
            <TabPanel value="3">
              <i>To Do...</i>
            </TabPanel>
          </TabContext>
        </Box>

        <Typography
          variant="h7"
          gutterBottom
          component="div"
          style={{ width: "40%", padding: 15, backgroundColor: "whitesmoke" }}
        >
          <Typography gutterBottom component="div">
            <b>Call Analysis</b>
          </Typography>
          <Grid container spacing={2} style={{ paddingTop: 15 }}>
            <Grid item xs={12}>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: 500, width: "50%" }}>
                  Call Completion
                </span>
                <span style={{ fontWeight: 500, width: "50%" }}>
                  {data.call_analysis.call_completion_rating}
                </span>
              </div>
              <span style={{ fontSize: 12 }}>
                <i>({data.call_analysis.call_completion_rating_reason})</i>
              </span>
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: 500, width: "50%" }}>
                  Task Completion
                </span>
                <span style={{ fontWeight: 500, width: "50%" }}>
                  {data.call_analysis.agent_task_completion_rating}
                </span>
              </div>
              <span style={{ fontSize: 12 }}>
                <i>
                  ({data.call_analysis.agent_task_completion_rating_reason})
                </i>
              </span>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <br />
          <Typography gutterBottom component="div">
            <b>Call Information</b>
          </Typography>
          <Grid container spacing={2} style={{ paddingTop: 15 }}>
            <Grid item xs={12}>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: 500, width: "50%" }}>Call ID</span>
              </div>
              <span style={{ fontSize: 15 }}>{data.call_id}</span>
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: 500, width: "50%" }}>Agent ID</span>
              </div>
              <span style={{ fontSize: 15 }}>{data.agent_id}</span>
            </Grid>
            <Grid item xs={12} style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <div>
                  <span style={{ fontWeight: 500, width: "50%" }}>From</span>
                </div>
                <span style={{ fontSize: 15 }}>
                  {data.from_number ? data.from_number : "--"}
                </span>
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <span style={{ fontWeight: 500, width: "50%" }}>To</span>
                </div>
                <span style={{ fontSize: 15 }}>
                  {data.to_number ? data.to_number : "--"}
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: 500, width: "50%" }}>Time</span>
              </div>
              <span style={{ fontSize: 15 }}>
                <i>
                  {formatDate(data.start_timestamp)} -{" "}
                  {formatDate(data.end_timestamp)}
                </i>
              </span>
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: 500, width: "50%" }}>Cost</span>
              </div>
              <span style={{ fontSize: 15 }}>{cost ? `$${cost}` : "--"}</span>
            </Grid>
          </Grid>
        </Typography>

        <ShareFeedback
          open={open}
          setOpen={setOpen}
          data={data}
          setFeedbacks={setFeedbacks}
        />
      </div>
    </Box>
  );
};

export default CallDetails;
