import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, campaigns, theme) {
  return {
    fontWeight:
      campaigns.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip({
  campaignList,
  role,
  campaigns,
  setCampaigns,
}) {
  const theme = useTheme();

  const getAgentName = (agentID) => {
    let result = campaignList.find((agent) => agent.agent_id === agentID);

    return result ? result.agent_name : "--";
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCampaigns(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <React.Fragment>
      {role !== undefined && (
        <React.Fragment>
          {role.agent_select ? (
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-chip-label">
                {role === "admin"
                  ? "Agent select not applicable for Admin Role"
                  : "Select Agents"}
              </InputLabel>
              <Select
                disabled={role === "admin"}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={campaigns}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label={
                      role === "admin"
                        ? "Agent Select not applicable for Admin Role"
                        : "Select Agents"
                    }
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={getAgentName(value)} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {campaignList.map((camp) => (
                  <MenuItem
                    key={camp.agent_id}
                    value={camp.agent_id}
                    style={getStyles(camp.agent_name, campaigns, theme)}
                  >
                    {camp.agent_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
