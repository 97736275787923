import axios from "axios";
const endpointAPI = process.env.REACT_APP_MINDVOICE_API;

const addUser = (token, data) => {
  return axios.post(`${endpointAPI}/user/`, data, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export { addUser };
