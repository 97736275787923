import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axios from "axios";
import Analytics from "../analytics/Analytics";
import DateInput from "../common/DatePicker";
import Calls from "../calls/Calls";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TestAudio from "./components/TestAudio";
import getStartAndEndOfDayTimestamps from "../../utils/getStartAndEndOfDayTimestamps";
import DateRange from "../common/DateRangePicker";
import { getAgentData } from "./utils/getCalls";
import NoDataAvailable from "../common/NoDataAvailable";
import PreparingData from "../common/PreparingData";
import UsersTable from "../users/UsersTable";
import { useAuth } from "../../hooks/useAuth";
import { getUserData } from "./utils/getUser";
import Modal from "../common/Modal";
import AddUserForm from "../users/AddUserForm";
import { getRoles } from "./utils/getRole";
import RolesTable from "../roles/RolesTable";
const endpointAPI = process.env.REACT_APP_RETELL_API;
const token = process.env.REACT_APP_RETELL_API_TOKEN;
const headers = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};
const Reports = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const getRoleDetail = (roleID) => {
    let result = roleList.find((role) => role.uuid === roleID);

    return result;
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  let { user, logout } = useAuth();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setLoading(true);
    getUserData(user.token)
      .then((res) => {
        console.log("res", res.data);
        setUserData(res.data.results);
        getRoles(user.token)
          .then((res) => {
            console.log(res.data.results);
            setRoleList(res.data.results);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log("Err", err.response.status);
        setLoading(false);
        if (err.response.status) {
          // alert("Session Expired");
          // logout();
        }
      });
  }, []);

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <TabContext value={value}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: 15,
                }}
              >
                <div>
                  <h2>User Management and Permission</h2>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="contained"
                    className="test-agent-button"
                    style={{
                      backgroundColor: "#aac955",
                    }}
                    onClick={handleClickOpen}
                    disabled={loading}
                  >
                    Add User
                  </Button>
                </div>
              </div>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label={<b>Users</b>} value="1" />
                    <Tab label={<b>Roles and Permission</b>} value="2" />
                  </TabList>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <TabPanel value="1" style={{ padding: 0 }}>
                {loading ? (
                  <PreparingData label="Preparing Data..." />
                ) : (
                  <React.Fragment>
                    {userData.length > 0 ? (
                      <UsersTable
                        userData={userData}
                        getRoleDetail={getRoleDetail}
                        roleList={roleList}
                      />
                    ) : (
                      <NoDataAvailable />
                    )}
                  </React.Fragment>
                )}
              </TabPanel>
              <TabPanel value="2" style={{ padding: 0 }}>
                <RolesTable
                  roleData={roleList}
                  getRoleDetail={getRoleDetail}
                  roleList={roleList}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </TabContext>
        <Modal open={open} handleClose={handleClose} title="Add New User">
          <AddUserForm
            handleClose={handleClose}
            getRoleDetail={getRoleDetail}
            roleList={roleList}
          />
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default Reports;
