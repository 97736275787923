import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

const callData = [
  // Sample callData array
  {
    call_id: "Jabr9TXYYJHfvl6Syypi88rdAHYHmcq6",
    start_timestamp: 1703302407333,
    end_timestamp: 1703302428855,
    disconnection_reason: "agent_hangup",
    call_analysis: {
      user_sentiment: "Positive",
      agent_sentiment: "Friendly",
    },
  },
  {
    call_id: "Abcd9TXYYJHfvl6Syypi88rdAHYHmcq6",
    start_timestamp: 1703302407333,
    end_timestamp: 1703302428855,
    disconnection_reason: "agent_hangup",
    call_analysis: {
      user_sentiment: "Negative",
      agent_sentiment: "Unfriendly",
    },
  },
  // Add more call data objects as needed
];

export default function CallDisconnectionBy({ callData }) {
  const [dataset, setdata] = React.useState([]);

  React.useEffect(() => {
    try {
      if (callData !== undefined) {
        const disconnectionByDate = callData.map((call) => {
          if (
            call.start_timestamp !== undefined ||
            call.disconnection_reason !== undefined
          ) {
            const options = { year: "numeric", month: "long", day: "numeric" };
            return {
              date: new Date(call.start_timestamp).toLocaleDateString(
                "en-US",
                options
              ),
              disconnectionReason: call.disconnection_reason,
            };
          }
        });

        const disconnectionReasonsByDay = disconnectionByDate.reduce(
          (acc, call) => {
            if (
              call.disconnectionReason !== undefined &&
              call.date !== undefined
            ) {
              if (!acc[call.date]) {
                acc[call.date] = {};
              }
              if (!acc[call.date][call.disconnectionReason]) {
                acc[call.date][call.disconnectionReason] = 0;
              }
              acc[call.date][call.disconnectionReason] += 1;
              return acc;
            }
          },
          {}
        );

        const data = Object.keys(disconnectionReasonsByDay).map((date) => {
          const reasons = disconnectionReasonsByDay[date];
          const entry = { date };
          Object.keys(reasons).forEach((reason) => {
            entry[reason] = reasons[reason];
          });
          return entry;
        });

        setdata(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [callData]);

  const chartSetting = {
    yAxis: [
      {
        label: "Number of Calls",
      },
    ],
    height: 600,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: "translateX(-10px)",
      },
    },
  };

  const series = [
    ...new Set(callData.map((call) => call.disconnection_reason)),
  ].map((reason) => ({
    dataKey: reason,
    label: reason ? reason.replace(/_/g, " ") : "",
  }));

  return (
    <div style={{ width: "100%", padding: 15 }}>
      <BarChart
        colors={[
          "#4e5ba6",
          "#f28e2c",
          "#e15759",
          "#76b7b2",
          "#59a14f",
          "#edc949",
          "#af7aa1",
          "#ff9da7",
          "#9c755f",
          "#bab0ab",
        ]}
        margin={{ top: 120 }}
        dataset={dataset}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "date",
            tickPlacement: "middle",
            tickLabelPlacement: "tick",
          },
        ]}
        series={series}
        {...chartSetting}
      />
    </div>
  );
}
