import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import SelectCampaign from "./SelectCampaign";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { getAgentList } from "../pages/utils/getAgentList";
import { getRoles } from "../pages/utils/getRole";
import { Alert, AlertTitle, Button } from "@mui/material";
import { addUser } from "../pages/utils/addUser";
import { useAuth } from "../../hooks/useAuth";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { capitalizeFirstLetter } from "../../utils/core_util";
function AddUserForm({ handleClose, roleList, getRoleDetail }) {
  let { user } = useAuth();
  const [userAdded, setUserAdded] = useState(false);
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
  });
  const [role, setRole] = useState("");
  const [errors, setErrors] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const [campaignList, setCampaignList] = useState([
    {
      agent_id: "046258af56e37d874c254108416fea19",
      last_modification_timestamp: 1720088035518,
      voice_id: "custom-Crystal",
      agent_name: "Abhishek-Chariot Energy",
    },
  ]);
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    getAgentList()
      .then((res) => {
        setCampaignList(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const createUser = () => {
    setErrors([]);
    setUserAdded(false);
    let checkedError = [];

    if (userDetails.first_name === "") {
      checkedError.push({
        field: "First name",
        message: "Fill out First name field",
      });
    }
    if (userDetails.last_name === "") {
      checkedError.push({
        field: "Last name",
        message: "Fill out Last name field",
      });
    }
    if (userDetails.email === "") {
      checkedError.push({
        field: "Email",
        message: "Fill out email field",
      });
    }
    if (userDetails.username === "") {
      checkedError.push({
        field: "Username",
        message: "Fill out Username field",
      });
    }
    if (role === "") {
      checkedError.push({
        field: "Role",
        message: "Please select a role",
      });
    }

    let data = userDetails;
    data.profile = {};
    if (getRoleDetail(role) !== undefined) {
      if (getRoleDetail(role).agent_select) {
        if (campaigns.length > 0) {
          data.profile.agents = campaigns;
        } else {
          checkedError.push({
            field: "Agents",
            message: "Please select an Agents",
          });
        }
      } else {
        data.profile.agents = [];
      }
      data.profile.agents = getRoleDetail(role).agent_select ? campaigns : [];
    }
    data.profile.role = role;

    if (checkedError.length > 0) {
      setErrors((prevState) => {
        return [...prevState, ...checkedError];
      });
    } else {
      addUser(user.token, data)
        .then((res) => {
          console.log("res", res.data);
          setUserDetails({
            first_name: "",
            last_name: "",
            username: "",
            email: "",
            password: "",
          });
          setRole("");
          setCampaigns([]);
          setUserAdded(true);
        })
        .catch((err) => {
          let errMessages = err.response.data;
          console.log(Object.keys(errMessages), errMessages["email"][0]);

          let errData = Object.keys(errMessages).map((item) => {
            return {
              field: capitalizeFirstLetter(item),
              message: errMessages[item][0],
            };
          });
          setErrors(errData);
        });
    }
  };
  return (
    <div style={{ paddingTop: 15 }}>
      {userAdded ? (
        <Alert
          icon={<CheckIcon fontSize="inherit" />}
          severity="success"
          style={{ marginBottom: 15 }}
        >
          Here is a gentle confirmation that the user creation was successful.
        </Alert>
      ) : null}
      {errors.length > 0 ? (
        <Alert
          icon={<ErrorIcon fontSize="inherit" />}
          severity="error"
          style={{ marginBottom: 15 }}
        >
          <AlertTitle style={{ fontWeight: 400 }}>
            Some of the fields are incorrect please check.
          </AlertTitle>
          {errors.map((item) => {
            return (
              <div style={{ fontSize: 15, padding: 5 }}>
                <span>
                  <b>{item.field}:</b> {item.message}
                </span>
              </div>
            );
          })}
        </Alert>
      ) : null}

      <div style={{ fontSize: 18, fontWeight: 400, paddingBottom: 15 }}>
        User Information
      </div>
      <Grid container spacing={2} style={{ paddingBottom: 30 }}>
        <Grid item xs={6}>
          <TextField
            value={userDetails.first_name}
            onChange={(e) => {
              setUserDetails((prevState) => ({
                ...prevState,
                first_name: e.target.value,
              }));
            }}
            fullWidth
            id="outlined-basic"
            label="First name"
            variant="outlined"
            placeholder="Ex. John"
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={userDetails.last_name}
            onChange={(e) => {
              setUserDetails((prevState) => ({
                ...prevState,
                last_name: e.target.value,
              }));
            }}
            fullWidth
            id="outlined-basic"
            label="Last name"
            variant="outlined"
            placeholder="Ex. Doe"
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={userDetails.username}
            onChange={(e) => {
              setUserDetails((prevState) => ({
                ...prevState,
                username: e.target.value,
              }));
            }}
            fullWidth
            id="outlined-basic"
            label="Username"
            variant="outlined"
            placeholder="Ex. johndoe1"
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={userDetails.email}
            onChange={(e) => {
              setUserDetails((prevState) => ({
                ...prevState,
                email: e.target.value,
              }));
            }}
            fullWidth
            id="outlined-basic"
            label="E-mail"
            variant="outlined"
            placeholder="Ex. johndoe@email.com"
            autoComplete="off"
          />
        </Grid>
      </Grid>
      <div style={{ fontSize: 18, fontWeight: 400, paddingBottom: 15 }}>
        Setup Password
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              value={userDetails.password}
              onChange={(e) => {
                setUserDetails((prevState) => ({
                  ...prevState,
                  password: e.target.value,
                }));
              }}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Grid>
        {/* <Grid item xs={5}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
          </FormControl>
        </Grid> */}
      </Grid>
      <div style={{ fontSize: 18, fontWeight: 400, paddingBottom: 15 }}>
        Roles and Agents
      </div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {roleList.length > 0 ? (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={role}
                label="Select Role"
                onChange={handleChange}
              >
                {roleList.map((item, i) => (
                  <MenuItem key={i} value={item.uuid}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <span>Getting Roles..</span>
          )}
        </Grid>
        <Grid item xs={8}>
          <SelectCampaign
            campaignList={campaignList}
            role={getRoleDetail(role)}
            campaigns={campaigns}
            setCampaigns={setCampaigns}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "end",
            paddingBottom: 15,
            paddingTop: 30,
          }}
        >
          <div>
            <Button
              variant="contained"
              className="test-agent-button"
              style={{
                backgroundColor: "#aac955",
              }}
              onClick={createUser}
            >
              Create User
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddUserForm;
