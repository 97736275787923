import axios from "axios";
const endpointAPI = process.env.REACT_APP_MINDVOICE_API;

const getRoles = (token) => {
  return axios.get(`${endpointAPI}/roles/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

const getRoleByID = async (token, roleID) => {
  return await axios.get(`${endpointAPI}/roles/${roleID}/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

const updateRole = async (token, roleID, data) => {
  let id = roleID;
  console.log("edit Data", data);
  let newData = {
    name: data.name,
    modify_agent: data.modify_agent,
    modify_user: data.modify_user,
    modify_roles: data.modify_roles,
    modify_report: data.modify_report,
    outbound: data.outbound,
    config: data.config,
    agent_select: data.agent_select,
  };
  return await axios.patch(`${endpointAPI}/roles/${id}/`, newData, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export { getRoles, getRoleByID, updateRole };
