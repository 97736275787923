import React from "react";
import CallTable from "./table/CallTable";

function Calls({ callData }) {
  return (
    <React.Fragment>
      <div style={{ border: "solid 1px #CCC", width: "100%" }}>
        <CallTable callData={callData} />
      </div>
    </React.Fragment>
  );
}

export default Calls;
