import React, { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";

export default function AgentSentiments({ callData }) {
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    if (callData !== undefined) {
      let sentimentCounts = callData.reduce((acc, call) => {
        const sentiment = call.call_analysis?.agent_sentiment ?? "Error"; // Default to 'unknown' if sentiment is missing
        if (acc[sentiment]) {
          acc[sentiment] += 1;
        } else {
          acc[sentiment] = 1;
        }
        return acc;
      }, {});

      const totalCalls = callData.length;
      const sentimentData = Object.entries(sentimentCounts).map(
        ([label, count], id) => ({
          id,
          value: count,
          label: `${label}: ${((count / totalCalls) * 100).toFixed(0)}%`,
          percentage: ((count / totalCalls) * 100).toFixed(0),
        })
      );

      setPieData(sentimentData);
      console.log("sentimentData", sentimentData);
    }
  }, [callData]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <PieChart
        colors={[
          "#667085",
          "#c11574",
          "#0b3d93",
          "#76b7b2",
          "#59a14f",
          "#edc949",
          "#af7aa1",
          "#ff9da7",
          "#9c755f",
          "#bab0ab",
        ]}
        series={[
          {
            data: pieData,
            highlightScope: { faded: "global", highlighted: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
          },
        ]}
        height={300}
      />
    </div>
  );
}
