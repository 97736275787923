import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { addFeedback } from "../../pages/utils/feedback";

export default function ShareFeedback({ open, setOpen, data, setFeedbacks }) {
  const { user } = useAuth();
  const [value, setValue] = React.useState("");
  const [note, setNote] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };

  const handleOutcomeChange = (e) => {
    setValue(e);
  };

  const handleSave = () => {
    console.log("feedback data", user.token);
    addFeedback(user.token, {
      call_id: data.call_id,
      outcome: value,
      notes: note,
      user: `${user.userData.first_name} ${user.userData.last_name}`,
    }).then((res) => {
      setFeedbacks((prevList) => {
        return [res.data, ...prevList];
      });
      handleClose();
      setValue("");
      setNote("");
    });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      style={{ padding: 15 }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          variant="h6"
          style={{ fontWeight: 400 }}
          gutterBottom
          component="div"
        >
          Share Feedback
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div style={{ paddingBottom: 5, paddingTop: 5, width: 300 }}>
          <FormControl fullWidth>
            <InputLabel>Outcome&nbsp;</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              label="Outcome"
              onChange={(e) => {
                handleOutcomeChange(e.target.value);
              }}
            >
              <MenuItem key="1" value="AI Did Not Understand Customer">
                AI Did Not Understand Customer
              </MenuItem>
              <MenuItem key="2" value="AI Picking up Background Noise">
                AI Picking up Background Noise
              </MenuItem>
              <MenuItem key="3" value="AI repeats itself">
                AI repeats itself
              </MenuItem>
              <MenuItem key="4" value="Bad AI Audio">
                Bad AI Audio
              </MenuItem>
              <MenuItem key="5" value="Incorrect Response">
                Incorrect Response
              </MenuItem>
              <MenuItem key="6" value="No Errors">
                No Errors
              </MenuItem>
              <MenuItem key="7" value="No Errors">
                Others
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ marginTop: 10 }}>
          <TextField
            id="outlined-multiline-flexible"
            multiline
            label="Notes and/or AI Training Needed"
            rows={4}
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            fullWidth
            inputProps={{
              maxLength: 250,
            }}
          />
        </div>
      </DialogContent>
      <DialogActions style={{ padding: 15, paddingRight: 25, paddingTop: 0 }}>
        <Button
          variant="contained"
          className="test-agent-button"
          style={{
            backgroundColor: "#aac955",
          }}
          onClick={handleSave}
          autoFocus
        >
          Save
        </Button>
        {/* <Button style={{color:"#333"}} onClick={handleClose}>Close</Button> */}
      </DialogActions>
    </Dialog>
  );
}
