import * as React from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";
import { updateRole } from "../pages/utils/getRole";
import { useAuth } from "../../hooks/useAuth";
function Row(item) {
  let { data, getRoleDetail, roleList } = item;
  const { user } = useAuth();
  const [roleDetails, setRoleDetails] = React.useState(data);
  const [disabled, setDisabled] = React.useState(true);
  const handleOpenEdit = () => {
    setDisabled(false);
  };
  const handleCloseEdit = () => {
    setDisabled(true);
  };

  const handleUpdateRole = () => {
    updateRole(user.token, data.uuid, roleDetails)
      .then((res) => {
        handleCloseEdit();
      })
      .catch((err) => console.log(err));
  };
  return (
    <React.Fragment>
      <TableRow>
        <TableCell align="left">
          <a style={{ cursor: "pointer", fontWeight: 500 }}>
            <u>{data.uuid}</u>
          </a>
        </TableCell>
        <TableCell align="left">
          <b>{data.name}</b>
        </TableCell>
        <TableCell align="left">
          <Checkbox
            onChange={(e) => {
              setRoleDetails((prevState) => {
                return {
                  ...prevState,
                  modify_agent: e.target.checked,
                };
              });
            }}
            checked={roleDetails.modify_agent}
            disabled={disabled}
          />
        </TableCell>
        <TableCell align="left">
          <Checkbox
            onChange={(e) => {
              setRoleDetails((prevState) => {
                return {
                  ...prevState,
                  modify_user: e.target.checked,
                };
              });
            }}
            checked={roleDetails.modify_user}
            disabled={disabled}
          />
        </TableCell>
        <TableCell align="left">
          <Checkbox
            onChange={(e) => {
              setRoleDetails((prevState) => {
                return {
                  ...prevState,
                  modify_roles: e.target.checked,
                };
              });
            }}
            checked={roleDetails.modify_roles}
            disabled={disabled}
          />
        </TableCell>
        <TableCell align="left">
          <Checkbox
            onChange={(e) => {
              setRoleDetails((prevState) => {
                return {
                  ...prevState,
                  modify_report: e.target.checked,
                };
              });
            }}
            checked={roleDetails.modify_report}
            disabled={disabled}
          />
        </TableCell>
        <TableCell align="left">
          <Checkbox
            onChange={(e) => {
              setRoleDetails((prevState) => {
                return {
                  ...prevState,
                  outbound: e.target.checked,
                };
              });
            }}
            checked={roleDetails.outbound}
            disabled={disabled}
          />
        </TableCell>
        <TableCell align="left">
          <Checkbox
            onChange={(e) => {
              setRoleDetails((prevState) => {
                return {
                  ...prevState,
                  config: e.target.checked,
                };
              });
            }}
            checked={roleDetails.config}
            disabled={disabled}
          />
        </TableCell>
        <TableCell align="left">
          <Checkbox
            onChange={(e) => {
              setRoleDetails((prevState) => {
                return {
                  ...prevState,
                  agent_select: e.target.checked,
                };
              });
            }}
            checked={roleDetails.agent_select}
            disabled={disabled}
          />
        </TableCell>
        <TableCell align="left">
          {disabled ? (
            <span onClick={handleOpenEdit} className="action-title">
              Edit
            </span>
          ) : (
            <span onClick={handleUpdateRole} className="action-title">
              Save
            </span>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function RolesTable({ roleData, getRoleDetail, roleList }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper style={{ width: "100%" }}>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Role ID</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Agents</TableCell>
              <TableCell align="left">User</TableCell>
              <TableCell align="left">Roles</TableCell>
              <TableCell align="left">Report</TableCell>
              <TableCell align="left">Outbound</TableCell>
              <TableCell align="left">Configuration</TableCell>
              <TableCell align="left">Select Agent</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roleData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => {
                return (
                  <Row
                    key={item.uuid}
                    data={item}
                    getRoleDetail={getRoleDetail}
                    roleList={roleList}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 15]}
        component="div"
        count={roleData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
