import React, { useState, useEffect } from "react";
import { getCallFeedback } from "../../pages/utils/feedback";
import { useAuth } from "../../../hooks/useAuth";
import formatDate from "../../../utils/date_formatter";
import PreparingData from "../../common/PreparingData";

function Feedbacks({ setFeedbacks, feedbacks, callID }) {
  let { user } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCallFeedback(user.token, callID)
      .then((res) => {
        setFeedbacks(res.data.results);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      {!loading ? (
        <React.Fragment>
          {feedbacks.length > 0 ? (
            <React.Fragment>
              {feedbacks.map((item) => {
                return (
                  <div>
                    <div>
                      <span
                        className="font"
                        style={{ fontWeight: 500, fontSize: 16, color: "#555" }}
                      >
                        {item.outcome}
                      </span>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                          }}
                        >
                          {item.user}
                        </span>
                        <span
                          style={{
                            fontWeight: 400,
                            fontSize: 12,
                            fontStyle: "italic",
                          }}
                        >
                          {formatDate(item.created_at)}
                        </span>
                      </div>
                    </div>
                    <p
                      style={{
                        fontSize: 16,
                        marginTop: 5,
                        borderBottom: "solid 1px #eee",
                        paddingBottom: 15,
                      }}
                    >
                      {item.notes}
                    </p>
                  </div>
                );
              })}
            </React.Fragment>
          ) : (
            <h2>No Feedback</h2>
          )}
        </React.Fragment>
      ) : (
        <h2>
          <PreparingData label="Loading Feedbacks.." />
        </h2>
      )}
    </div>
  );
}

export default Feedbacks;
