import * as React from "react";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

export default function AverageDuration({ callData }) {
  const valueFormatter = (value) => `${value.toFixed(1)}s`;
  const options = { year: "numeric", month: "long", day: "numeric" };
  const [dataset, setdata] = React.useState([]);
  React.useEffect(() => {
    if (callData !== undefined) {
      const callDurations = callData.map((call) => ({
        date: new Date(call.start_timestamp).toLocaleDateString(
          "en-US",
          options
        ),
        duration: (call.end_timestamp - call.start_timestamp) / 1000,
      }));

      const durationsByDay = callDurations.reduce((acc, call) => {
        if (!acc[call.date]) {
          acc[call.date] = { totalDuration: 0, count: 0 };
        }
        acc[call.date].totalDuration += call.duration;
        acc[call.date].count += 1;
        return acc;
      }, {});

      const averageDurationsByDay = Object.keys(durationsByDay).map((date) => ({
        date,
        averageDuration:
          durationsByDay[date].totalDuration / durationsByDay[date].count,
      }));

      const dates = averageDurationsByDay.map((data) => data.date);
      const durations = averageDurationsByDay.map(
        (data) => data.averageDuration
      );

      const data = dates.map((date, index) => ({
        month: date,
        averageDuration: durations[index],
      }));
      setdata(data);
    }
  }, [callData]);

  const chartSetting = {
    yAxis: [
      {
        label: "Average Call Duration (s)",
      },
    ],
    series:
      callData.length > 0
        ? [
            {
              dataKey: "averageDuration",
              label: "Average Duration",
              valueFormatter,
            },
          ]
        : [],
    height: 400,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: "translateX(-10px)",
      },
    },
  };
  return (
    <div style={{ width: "100%", padding: 15 }}>
      <BarChart
        dataset={dataset}
        colors={[
          "#4e5ba6",
          "#f28e2c",
          "#e15759",
          "#76b7b2",
          "#59a14f",
          "#edc949",
          "#af7aa1",
          "#ff9da7",
          "#9c755f",
          "#bab0ab",
        ]}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "month",
            tickPlacement: "middle",
            tickLabelPlacement: "middle",
          },
        ]}
        {...chartSetting}
      />
    </div>
  );
}
