import { Box, Container, Grid, TextField } from "@mui/material";
import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useAuth } from "../../hooks/useAuth";

const Config = () => {
  const { apiConfig, changeConfig } = useAuth();
  const [api, setAPI] = React.useState(apiConfig);

  const handleChange = (event) => {
    setAPI(event.target.value);
    changeConfig(event.target.value);
  };
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: 15,
              }}
            >
              <h2>Settings & Config</h2>
            </div>
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  API Selection
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={api}
                  label="API Selection"
                  onChange={handleChange}
                >
                  <MenuItem value="retell">ReTell</MenuItem>
                  <MenuItem value="mindvoice">MindVoice</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Config;
