const calculateCallData = (callData) => {
    let totalDurationMs = 0;
  
    callData.forEach(call => {
      if(call.start_timestamp && call.end_timestamp){
        const durationMs = call.end_timestamp - call.start_timestamp;
        totalDurationMs += durationMs;
      }
    });
  
    // Convert milliseconds to minutes
    const totalDurationMinutes = totalDurationMs / (1000 * 60);

     // Calculate the average duration in milliseconds
     const averageDurationMs = totalDurationMs / callData.length;
  
     // Convert milliseconds to minutes
     const averageDurationMinutes = averageDurationMs ? averageDurationMs / (1000 * 60) : 0;
     const averageDurationSeconds = averageDurationMinutes * 60;
    return {totalDurationMinutes, averageDurationMinutes, averageDurationSeconds};
};

const getConversion = (callData) => {
  let conversion = callData.filter(call => call.disconnection_reason === "call_transfer");
  
  return conversion.length
};
export {
    calculateCallData,
    getConversion
}