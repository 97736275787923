import React, { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";

export default function TaskCompletions({ callData }) {
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    if (callData !== undefined) {
      let completionCounts = callData.reduce((acc, call) => {
        const completion =
          call.call_analysis?.agent_task_completion_rating ?? "Error"; // Default to 'unknown' if sentiment is missing
        if (acc[completion]) {
          acc[completion] += 1;
        } else {
          acc[completion] = 1;
        }
        return acc;
      }, {});

      const totalCalls = callData.length;
      const completionData = Object.entries(completionCounts).map(
        ([label, count], id) => ({
          id,
          value: count,
          label: `${label}: ${((count / totalCalls) * 100).toFixed(0)}%`,
          percentage: ((count / totalCalls) * 100).toFixed(0),
        })
      );

      setPieData(completionData);
    }
  }, [callData]);

  return (
    <div style={{ width: "100%" }}>
      <PieChart
        colors={[
          "#c11574",
          "#0b3d93",
          "#76b7b2",
          "#59a14f",
          "#edc949",
          "#af7aa1",
          "#ff9da7",
          "#9c755f",
          "#bab0ab",
        ]}
        series={[
          {
            data: pieData,
            highlightScope: { faded: "global", highlighted: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
          },
        ]}
        height={300}
      />
    </div>
  );
}
