import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axios from "axios";
import Analytics from "../analytics/Analytics";
import DateInput from "../common/DatePicker";
import Calls from "../calls/Calls";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TestAudio from "./components/TestAudio";
import getStartAndEndOfDayTimestamps from "../../utils/getStartAndEndOfDayTimestamps";
import DateRange from "../common/DateRangePicker";
import { getAgentData } from "./utils/getCalls";
import Export from "./components/Export";
import { useAuth } from "../../hooks/useAuth";
import { getRoleByID, getRoles } from "./utils/getRole";
import { set } from "date-fns";
const endpointAPI = process.env.REACT_APP_RETELL_API;
const token = process.env.REACT_APP_RETELL_API_TOKEN;
const headers = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};
const CancelToken = axios.CancelToken;
const Reports = () => {
  let { user, logout, apiConfig } = useAuth();

  const [callData, setCallData] = useState([]);
  const [originalCallData, setOriginalCallData] = useState([]);
  const [agent, setAgent] = useState("");
  const [agentData, setAgentData] = useState([]);
  const [disconnectionReason, setDisconnectionReason] = useState("All");
  const [userSentiment, setUserSentiment] = useState("All");
  const [agentSentiment, setAgentSentiment] = useState("All");
  const [loading, setLoading] = useState(false);
  const [agentLoading, setAgentLoading] = useState(false);
  const [agentDetails, setAgentDetails] = useState({});
  const [limit, setLimit] = useState(1000000);
  const [userRole, setUserRole] = useState({});
  const [dataError, setDataError] = useState(false);
  let dateToday = new Date();
  const [date, setDate] = useState([dayjs(dateToday), dayjs(dateToday)]);

  const getCallData = () => {
    setLoading(true);
    setDataError(false);
    setAgentLoading(true);
    let timestamps = getStartAndEndOfDayTimestamps(date[0], date[1]);
    if (!user.userData.profile) {
      localStorage.clear();
      logout();
    }
    axios
      .get(`${endpointAPI}/list-agents`, headers)
      .then(async (res) => {
        let userRoleDetails = await getRoleByID(
          user.token,
          user.userData.profile.role
        )
          .then((res) => {
            setUserRole(res.data);
            return res.data;
          })
          .catch((err) => {
            logout();
          });
        let firstAgentData = null;
        if (userRoleDetails.agent_select) {
          let newAgentData = user.userData.profile.agents.map((id) =>
            res.data.find((agent) => agent.agent_id === id)
          );

          if (newAgentData.length > 0) {
            firstAgentData = newAgentData[0];
            setAgentData(newAgentData);
          } else {
            firstAgentData = res.data[0];
            setAgentData(res.data);
          }
        } else {
          firstAgentData = res.data[0];
          setAgentData(res.data);
        }

        setAgent(firstAgentData.agent_id);
        setAgentDetails(firstAgentData);
        localStorage.setItem("currentAgent", firstAgentData.agent_id);
        setAgentLoading(false);

        getAgentData(
          firstAgentData.agent_id,
          timestamps.startOfDayTimestamp,
          timestamps.endOfDayTimestamp,
          limit,
          user.token,
          apiConfig
        )
          .then((res) => {
            setCallData(res.data);
            setOriginalCallData(res.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            if (err) {
              console.log("error", err);
              if (err.response) {
                if (err.response.status === 500) {
                  setDataError(true);
                }
              }
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCallData();
  }, []);

  const onDateChange = (newValue) => {
    setDate(newValue);
    setLoading(true);
    setDataError(false);
    let startDate = new Date(newValue[0]);
    let endDate = new Date(newValue[1]);
    let timestamps = getStartAndEndOfDayTimestamps(startDate, endDate);

    getAgentData(
      agent,
      timestamps.startOfDayTimestamp,
      timestamps.endOfDayTimestamp,
      limit,
      user.token,
      apiConfig
    )
      .then((res) => {
        setCallData(res.data);
        setOriginalCallData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err) {
          console.log("error", err);
          if (err.response) {
            if (err.response.status === 500) {
              setDataError(true);
            }
          }
        }
      });
  };
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAgentChange = (event) => {
    let timestamps = getStartAndEndOfDayTimestamps(date[0], date[1]);
    setDataError(false);
    setLoading(true);
    setDisconnectionReason("All");
    setAgent(event.target.value);

    getAgentData(
      event.target.value,
      timestamps.startOfDayTimestamp,
      timestamps.endOfDayTimestamp,
      limit,
      user.token,
      apiConfig
    )
      .then((res) => {
        setCallData(res.data);
        setOriginalCallData(res.data);
        axios
          .get(`${endpointAPI}/get-agent/${event.target.value}`, headers)
          .then((res) => {
            setAgentDetails(res.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        if (err) {
          console.log("error", err);
          if (err.response) {
            if (err.response.status === 500) {
              setDataError(true);
            }
          }
        }
      });
  };

  useEffect(() => {
    console.log(disconnectionReason, userSentiment, agentSentiment);

    const filteredData = originalCallData.filter((item) => {
      if (item.call_analysis) {
        return (
          (disconnectionReason === "All" ||
            item.disconnection_reason === disconnectionReason) &&
          (userSentiment === "All" ||
            item.call_analysis.user_sentiment === userSentiment) &&
          (agentSentiment === "All" ||
            item.call_analysis.agent_sentiment === agentSentiment)
        );
      }
    });
    setCallData(filteredData);
  }, [disconnectionReason, userSentiment, agentSentiment]);

  const handleReasonChange = (targetValue) => {
    setDisconnectionReason(targetValue);
  };
  const handleUserSentimentChange = (targetValue) => {
    setUserSentiment(targetValue);
  };
  const handleAgentSentimentChange = (targetValue) => {
    setAgentSentiment(targetValue);
  };
  const [isTyping, setIsTyping] = useState(false);
  const typingTimeout = 500; // Timeout duration in milliseconds
  const onLimitChange = (event) => {
    setLimit(event.target.value);
    setIsTyping(true);
  };
  useEffect(() => {
    if (isTyping) {
      const timeout = setTimeout(() => {
        setIsTyping(false);
        // Perform the action you want to do after user stops typing
        let value = limit;
        setDataError(false);
        setLoading(true);
        setLimit(value);
        if (value > 0) {
          let startDate = new Date(date[0]);
          let endDate = new Date(date[1]);
          let timestamps = getStartAndEndOfDayTimestamps(startDate, endDate);
          getAgentData(
            agent,
            timestamps.startOfDayTimestamp,
            timestamps.endOfDayTimestamp,
            value,
            user.token,
            apiConfig
          )
            .then((res) => {
              setCallData(res.data);
              setDisconnectionReason("All");
              setOriginalCallData(res.data);
              setLoading(false);
            })
            .catch((err) => {
              if (err) {
                console.log("error", err);
                if (err.response) {
                  if (err.response.status === 500) {
                    setDataError(true);
                  }
                }
              }
            });
        }
      }, typingTimeout);

      return () => clearTimeout(timeout);
    }
  }, [limit, isTyping]);

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        {console.log("API CONFIG", apiConfig)}
        {dataError ? (
          <h2 style={{ color: "red" }}>
            Recieved Error on Getting Call Data. Please try again!
          </h2>
        ) : null}
        {console.log(agentDetails)}
        <TabContext value={value}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: 15,
                }}
              >
                <div>
                  <h2>
                    {!loading
                      ? `${agentDetails.agent_name}'s Reports`
                      : "Please wait.."}
                  </h2>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Box>
                    {!loading ? (
                      <FormControl variant="standard">
                        <InputLabel>Agent name</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={agent}
                          label="Agent"
                          disableUnderline
                          onChange={handleAgentChange}
                          IconComponent={() => <ExpandMoreIcon />}
                        >
                          {agentData.map((item) => {
                            return (
                              <MenuItem
                                key={item.agent_name}
                                value={item.agent_id}
                              >
                                {item.agent_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    ) : (
                      <i>Getting agent details...</i>
                    )}
                  </Box>
                  &nbsp; &nbsp;
                  <TestAudio agentID={agent} loading={loading} />
                </div>
              </div>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    style={{ width: "80%" }}
                  >
                    <Tab label={<b>Analytics</b>} value="1" />
                    <Tab label={<b>Calls</b>} value="2" />
                    <Tab label={<b>Ask Me</b>} value="3" />
                  </TabList>
                  <Export
                    loading={loading}
                    callData={callData}
                    agentDetails={agentDetails}
                  />
                </Box>
              </Box>
            </Grid>
            {value !== "3" ? (
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                {/* <DateInput /> */}
                <DateRange
                  date={date}
                  setDate={setDate}
                  onDateChange={onDateChange}
                />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <Box sx={{ minWidth: 250 }}>
                  <FormControl fullWidth>
                    <InputLabel>Disconnection Reason&nbsp;</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={disconnectionReason}
                      label="Disconnection Reason"
                      onChange={(e) => {
                        handleReasonChange(e.target.value);
                      }}
                    >
                      <MenuItem key="all" value="All">
                        All
                      </MenuItem>
                      {[
                        ...new Set(
                          originalCallData.map(
                            (call) => call.disconnection_reason
                          )
                        ),
                      ].map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <Box sx={{ minWidth: 170 }}>
                  <FormControl fullWidth>
                    <InputLabel>User Sentiment</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userSentiment}
                      label="User Sentiment"
                      onChange={(e) => {
                        handleUserSentimentChange(e.target.value);
                      }}
                    >
                      <MenuItem key="all" value="All">
                        All
                      </MenuItem>
                      {[
                        ...new Set(
                          originalCallData.map((call) => {
                            if (call.call_analysis) {
                              return call.call_analysis.user_sentiment;
                            }
                          })
                        ),
                      ].map((item) => {
                        if (item) {
                          return (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                </Box>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <Box sx={{ minWidth: 170 }}>
                  <FormControl fullWidth>
                    <InputLabel>Agent Sentiment</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={agentSentiment}
                      label="Agent Sentiment"
                      onChange={(e) => {
                        handleAgentSentimentChange(e.target.value);
                      }}
                    >
                      <MenuItem key="all" value="All">
                        All
                      </MenuItem>
                      {[
                        ...new Set(
                          originalCallData.map((call) => {
                            if (call.call_analysis) {
                              return call.call_analysis.agent_sentiment;
                            }
                          })
                        ),
                      ].map((item) => {
                        if (item) {
                          return (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                </Box>
                {/* &nbsp; &nbsp; &nbsp;&nbsp;
                <Box sx={{ minWidth: 170 }}>
                  <TextField
                    id="outlined-basic"
                    label="Data Limit"
                    fullWidth
                    style={{ maxWidth: 170 }}
                    variant="outlined"
                    value={limit}
                    onChange={onLimitChange}
                  />
                </Box> */}
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Box sx={{ minWidth: 700 }}>
                  <TextField
                    id="outlined-basic"
                    label="Ask me and start getting smart business insights.."
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <TabPanel value="1" style={{ padding: 0 }}>
                {loading ? (
                  <div
                    style={{
                      // border:"solid 1px #eeecec",
                      marginTop: 20,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 25,
                      flexDirection: "column",
                    }}
                  >
                    <h2 style={{ color: "#444" }}>Loading Analytics...</h2>
                    <br />
                    <br />
                    <CircularProgress />
                  </div>
                ) : (
                  <Analytics callData={callData} loading={loading} />
                )}
              </TabPanel>
              <TabPanel value="2" style={{ padding: 0 }}>
                {loading ? (
                  <div
                    style={{
                      // border:"solid 1px #eeecec",
                      marginTop: 20,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 25,
                      flexDirection: "column",
                    }}
                  >
                    <h2 style={{ color: "#444" }}>Preparing Table...</h2>
                    <br />
                    <br />
                    <CircularProgress />
                  </div>
                ) : (
                  <React.Fragment>
                    {callData.length > 0 ? (
                      <Calls callData={callData} />
                    ) : (
                      <div
                        style={{
                          // border:"solid 1px #eeecec",
                          marginTop: 20,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 25,
                        }}
                      >
                        <h2 style={{ color: "#444" }}>No Data Available</h2>
                        <img
                          src="./images/undraw_empty_re_opql.svg"
                          width={500}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )}
              </TabPanel>
              <TabPanel value="3" style={{ padding: 0 }}>
                <h2>To do..</h2>
              </TabPanel>
            </Grid>
          </Grid>
        </TabContext>
      </Container>
    </React.Fragment>
  );
};

export default Reports;
