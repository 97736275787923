const getStartAndEndOfDayTimestamps = (startDate, endDate) => {
  let date1 = new Date(startDate);
  let date2 = new Date(endDate);

  // Create a new Date object for the given date at 00:00:00 local time
  let startOfDay = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate(),
    0,
    0,
    0,
    0
  );
  let endOfDay = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate(),
    23,
    59,
    59,
    999
  );

  // Get the timestamps in milliseconds
  let startOfDayTimestamp = startOfDay.getTime();
  let endOfDayTimestamp = endOfDay.getTime();

  return { startOfDayTimestamp, endOfDayTimestamp };
};

export default getStartAndEndOfDayTimestamps;
