import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems } from "./listItems";
import Reports from "../pages/Reports";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../../hooks/useAuth";
import { Avatar } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import { getRoleByID } from "../pages/utils/getRole";
import { Navigate } from "react-router-dom";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Omind MindVoice
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard({ component }) {
  const { logout, user } = useAuth();
  if (JSON.parse(localStorage.getItem("user")).userData === undefined) {
    logout();
  }
  if (!JSON.parse(localStorage.getItem("user")).userData.profile) {
    logout();
  }
  const [userRoleDetail, setUserRoleDetail] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let location = useLocation();

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleLogout = () => {
    logout();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (!user.userData.profile) {
      localStorage.clear();
      logout();
    }

    if (user.userData.profile.role !== undefined) {
      getRoleByID(user.token, user.userData.profile.role)
        .then((res) => {
          setUserRoleDetail(res.data);
          console.log("User Role Data", res.data);
          let userRoleDetail = res.data;
          let navList = [
            {
              to: "/user",
              label: "Create Agent",
              permission: userRoleDetail.modify_agent,
            },
            {
              to: "/report",
              label: "Report",
              permission: userRoleDetail.modify_report,
            },
            {
              to: "/outbound",
              label: "Outbound",
              permission: userRoleDetail.outbound,
            },
            {
              to: "/extractor",
              label: "Extractor",
              permission: true,
            },
            {
              to: "/permission",
              label: "Permission",
              permission: userRoleDetail.modify_roles,
            },
            {
              to: "/config",
              label: "Config",
              permission: true,
            },
          ];

          let getPermission = navList.find(
            (item) => item.to === location.pathname
          );
          if (!getPermission.permission) {
            logout();
          }
          return res.data;
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 401) {
              alert("Session Expire!");
              logout();
            }
          }
        });
    } else {
      alert("Session Expire!");
      logout();
    }
  }, [location]);

  const popOverOpen = Boolean(anchorEl);
  const id = popOverOpen ? "simple-popover" : undefined;
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              style={{ color: "#333" }}
              variant="h6"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              MindVoice Client Portal
            </Typography>

            <div>
              <IconButton onClick={handleClick}>
                <Avatar
                  alt={
                    user.userData
                      ? user.userData.first_name.charAt(0).toUpperCase() +
                        user.userData.first_name.slice(1)
                      : null
                  }
                  src={`https://avatar.iran.liara.run/username?username=${
                    user.userData ? user.userData.first_name : null
                  } ${user.userData ? user.userData.last_name : null}`}
                />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={popOverOpen}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                style={{ textAlign: "center" }}
              >
                <div
                  style={{
                    padding: 15,
                    minWidth: 190,
                    borderBottom: "solid 1px #eee",
                  }}
                >
                  <span className="title-font" style={{ color: "#111" }}>
                    Hi{" "}
                    {user.userData
                      ? user.userData.first_name.charAt(0).toUpperCase() +
                        user.userData.first_name.slice(1)
                      : null}{" "}
                    {user.userData
                      ? user.userData.last_name.charAt(0).toUpperCase() +
                        user.userData.last_name.slice(1)
                      : null}
                    !
                  </span>
                  <br />
                  <span
                    className="title-font"
                    style={{
                      color: "#111",
                      fontSize: 14,
                    }}
                  >
                    {user.userData.email}
                  </span>
                </div>
                <MenuItem className="menu-item">Profile</MenuItem>
                <MenuItem className="menu-item" onClick={handleLogout}>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: [1],
            }}
          >
            <img src="./images/logo.svg" style={{ marginLeft: 10 }} />
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems(location, userRoleDetail)}
            {/* <Divider sx={{ my: 1 }} />
            {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          {component}
          <Toolbar />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
