import axios from "axios";
const endpointAPI = process.env.REACT_APP_MINDVOICE_API;

const editUser = (token, data, username) => {
  return axios.patch(`${endpointAPI}/user/${username}/`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export { editUser };
