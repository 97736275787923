import axios from "axios";
const retellEndpointAPI = process.env.REACT_APP_RETELL_API;
const retellToken = process.env.REACT_APP_RETELL_API_TOKEN;
const mindvoiceAPI = process.env.REACT_APP_MINDVOICE_API;

let cancelTokenSource = null;

const getAgentData = (
  agent_id,
  start_timestamp,
  end_timestamp,
  limit,
  userToken,
  api
) => {
  // Cancel the previous request if a new one is made
  if (cancelTokenSource) {
    cancelTokenSource.cancel("Operation canceled due to new request.");
  }

  // Create a new cancel token
  cancelTokenSource = axios.CancelToken.source();

  let params = {
    agent_id: agent_id,
    after_start_timestamp: start_timestamp,
    before_end_timestamp: end_timestamp,
    limit,
  };

  const config = {
    params: params,
    cancelToken: cancelTokenSource.token,
  };

  if (api === "mindvoice") {
    config.headers = {
      Authorization: `Token ${userToken}`,
    };
    return axios.get(`${mindvoiceAPI}/calldata/agent/`, config);
  } else {
    config.headers = {
      Authorization: `Bearer ${retellToken}`,
    };
    return axios.get(`${retellEndpointAPI}/list-calls`, config);
  }
};

export { getAgentData };
