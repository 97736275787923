import { capitalize } from "@mui/material";

import React from "react";

function Transcripts({ jumpToTime, transcript }) {
  function formatTime(decimalTime) {
    let minutes = Math.floor(decimalTime / 60);
    let remainingSeconds = decimalTime % 60;
    let formattedTime = `${minutes}:${remainingSeconds
      .toString()
      .padStart(2, "0")}
      `;
    return formattedTime;
  }
  return (
    <div
      style={{
        padding: 10,
      }}
    >
      {transcript.length > 0 ? (
        <React.Fragment>
          {transcript.map((item, index) => (
            <div
              key={index}
              style={{ display: "flex", padding: 8, cursor: "pointer" }}
              className="transcript-item"
              onClick={() => {
                if (!item.tool_call_id) {
                  if (item.words) {
                    console.log(item.words[0].start);
                    jumpToTime(item.words[0].start.toFixed());
                  }
                }
              }}
            >
              <div style={{ width: "90%" }}>
                <span style={{ fontWeight: 500, textDecoration: "underlined" }}>
                  {capitalize(item.role)}: &nbsp;
                </span>
                <span style={{ fontSize: 15 }}>
                  {item.tool_call_id ? (
                    <b>{item.name ? item.name : item.content}</b>
                  ) : (
                    item.content
                  )}
                </span>
                {item.tool_call_id ? (
                  <React.Fragment>
                    <div style={{ padding: 15 }}>{item.arguments}</div>
                  </React.Fragment>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                  width: "10%",
                }}
              >
                {item.words ? (
                  <React.Fragment>
                    {item.words.length > 0 ? (
                      <a
                        style={{
                          fontWeight: 500,
                          fontSize: 13,
                          cursor: "pointer",
                        }}
                      >
                        {item.words[0].start
                          ? formatTime(item.words[0].start.toFixed())
                          : "--"}
                      </a>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          ))}
        </React.Fragment>
      ) : null}
    </div>
  );
}

export default Transcripts;
