import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import UserSentiments from "./charts/UserSentiments";
import AverageDuration from "./charts/AverageDuration";
import CallDisconnectionBy from "./charts/CallDisconnectionBy";
import AgentSentiments from "./charts/AgentSentiments";
import CallTransfer from "./charts/CallTransfer";
import Loader from "../common/Loader";
import {
  calculateCallData,
  getConversion,
} from "../../utils/calculateCallData";
import CallCompletions from "./charts/CallCompletions";
import TaskCompletions from "./charts/TaskCompletion";

const ReportBox = ({ label, value }) => {
  return (
    <Grid item xs={6}>
      <div className="report-box-item">
        <Typography
          variant="h2"
          component="h5"
          className="report-box-item-value"
        >
          <b>{!value ? "--" : value}</b>
        </Typography>
        <Typography
          variant="h7"
          component="h4"
          style={{ marginTop: 5, color: "#555" }}
        >
          {label}
        </Typography>
      </div>
    </Grid>
  );
};
const ChartBox = (props) => {
  return (
    <Grid item xs={12} className="grid-item">
      <div className="chart-box-item">
        <Typography variant="h7" component="h4" className="chart-box-title">
          {props.label}
        </Typography>
        <div className="chart-container">{props.children}</div>
      </div>
    </Grid>
  );
};

const Analytics = ({ callData, loading }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    setDataLoaded(true);
  }, []);

  return (
    <React.Fragment>
      {dataLoaded ? (
        <Grid container>
          <Grid
            item
            xs={12}
            lg={6}
            md={12}
            style={{ display: "flex", padding: 0 }}
          >
            <Grid container className="report-box-container" spacing={1}>
              <ReportBox label="Total Calls" value={callData.length} />
              <ReportBox label="Conversions" value={getConversion(callData)} />
              <ReportBox
                label="Average Handling Time"
                value={`${Math.round(
                  calculateCallData(callData).averageDurationSeconds.toFixed(2)
                )}s`}
              />
              <ReportBox
                label="Total Talk Time (minutes)"
                value={calculateCallData(callData).totalDurationMinutes.toFixed(
                  1
                )}
              />
              <ChartBox label="Call Disconnected By">
                <CallDisconnectionBy callData={loading ? [] : callData} />
              </ChartBox>
              <ChartBox label="Agent Sentiments">
                <AgentSentiments callData={loading ? [] : callData} />
              </ChartBox>
              <ChartBox label="Agent Task Completion">
                <TaskCompletions callData={loading ? [] : callData} />
              </ChartBox>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            md={12}
            style={{ display: "flex", paddingLeft: 10, height: 940 }}
          >
            <Grid container spacing={1}>
              <ChartBox label="Average Duration Per Day">
                <AverageDuration callData={loading ? [] : callData} />
              </ChartBox>
              <ChartBox label="Call Transfer Per Day">
                <CallTransfer callData={loading ? [] : callData} />
              </ChartBox>
              <ChartBox label="User Sentiments">
                <UserSentiments callData={loading ? [] : callData} />
              </ChartBox>
              <ChartBox label="Call Completion">
                <CallCompletions callData={loading ? [] : callData} />
              </ChartBox>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
};

export default Analytics;
